import React, { useEffect, useMemo, useState } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table, SearchInput } from '../../components';
import { Button, Loader, NewSelect, Typography } from './../../common';
import useDebounce from '../../components/debounce-hook';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useHistory } from 'react-router-dom';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { Dropdown } from 'react-bootstrap';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ADVERTISER_STATUS } from '../../libs/constants';
import getSessionData from './../../service-utils/session-util';
import Chip from '@material-ui/core/Chip';
import { handleMsgOnForm, navigateToAdvertiser } from '../../common/utils';
import { ConformationBox } from '../../components/conformationBox';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// Import setAdvertiserIdCookie function
import { setAdvertiserIdCookie } from './../../service-utils/session-util';

export const Advertiser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [deleteId, setDeleteId] = useState();
  const debouncedValue = useDebounce(searchText, 500);
  const [status, setStatus] = useState(ADVERTISER_STATUS[0]);
  const { agencyId, advertiserId } = getSessionData();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);

  // Load the selected advertiser ID from the cookie on component mount
  useEffect(() => {
    const storedAdvertiserId = Cookies.get('selectedAdvertiserId');
    if (storedAdvertiserId) {
      const selectedAdv = { value: storedAdvertiserId, label: storedAdvertiserId }; // Replace with your actual data structure
      setSelectedAdvertiser(selectedAdv);
    }
  }, []);

  const { data, isLoading, refetch } = useQuery(
    ['ADVERTISER_DATA', pageNumber, pageLimit],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        name: debouncedValue,
        enabled: status.value,
        agencyId,
        advertiserId: selectedAdvertiser?.value,
      };
      const response = await Services.advertisersSearch(queryString.stringify(queryStringParams));
      return response.data;
    },
  );

  const { mutate: handleDelete } = useMutation(
    async (id) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.advertiserDelete(id, query);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('Advertiser deleted successfully.', { variant: 'success' });
        setDeleteId();
        refetch();
      },
    },
  );
  const handleButtonClick = () => {
    history.push('/customer-create');
  };

  useEffect(() => {
    if (selectedAdvertiser) {
      Cookies.set('selectedAdvertiserId', selectedAdvertiser.value);
    }
  }, [selectedAdvertiser]);

  const handleAddAdvertiser = () => {
    history.push(`/customer-create`);
  };

  const handleEditAdvertiser = (advertiserId) => {
    if (advertiserId) {
      history.push(`/customer/${advertiserId}`);
    }
  };

  const handleAdvertiserClick = (advertiserId) => {
    setAdvertiserIdCookie(advertiserId);
  };

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Advertisers
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  const tableData = useMemo(() => {
    return data?.advertiserList || [];
  }, [data]);

  const manageButton = (row) => {
    return (
      <>
        <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
          <Dropdown.Toggle variant="link">
            <MoreHorizIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-lg">
            <Dropdown.Item onClick={() => handleEditAdvertiser(row.id)}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => setDeleteId(row.id)}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const getTableHeader = [
    {
      dataField: 'name',
      text: 'Advertiser Name',
      formatter: (col, row) => (
        <>
          <div>
            {row.liveCampaigns > 0 ? (
              <span style={{ color: 'green' }}>
                <FiberManualRecordIcon style={{ fontSize: '12px' }} />
              </span>
            ) : (
              <span style={{ color: 'green' }}>
                {/* <FiberManualRecordIcon style={{ fontSize: '15px' }} /> */}
              </span>
            )}
            <a
              className="ml-1"
              href={`/advertiser/dashboard`}
              onClick={() => handleAdvertiserClick(row.id)}
            >
              {col}
            </a>
          </div>
        </>
      ),
    },
    { dataField: 'totalCampaigns', text: 'Total Campaigns' },
    { dataField: 'liveCampaigns', text: 'Live Campaigns' },
    { dataField: 'seat', text: 'Seat' },
    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col) => (
        <>
          {col ? (
            <Chip label="Enabled" className="alert-success" />
          ) : (
            <Chip label="Disabled" className="alert-danger" />
          )}
        </>
      ),
    },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => manageButton(row),
    },
  ];

  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <NewSelect
            required
            options={ADVERTISER_STATUS}
            value={status}
            onChange={(obj) => setStatus(obj)}
            placeholder={`Status`}
            isMulti={false}
            className="mt-n3 ml-3 "
          />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3 btn-add"
            startIcon={<FilterListIcon />}
            onClick={() => refetch()}
          >
            Apply
          </Button>
        </div>

        <Button
          variant="contained"
          className="btn btn-primary d-flex btn-38"
          // startIcon={<AddIcon />}
          onClick={() => handleAddAdvertiser()}
        >
          New Advertiser
        </Button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Table
            keyField="id"
            tableData={tableData}
            tableHeader={getTableHeader}
            isRemote={true}
            onPageChangeFun={handleTableChange}
            totalSize={data?.totalElements}
            activePage={pageNumber}
            sizePerPage={pageLimit}
            wrapperClasses="scroll-visible"
            defaultSorted={[
              {
                dataField: 'firstName',
                order: 'asc',
              },
            ]}
            onButtonClick={handleButtonClick}
            buttonLabel="Click here to new advertiser"
            additionalText="To get your advertisements rolling, create your advertiser profile today. Let’s make your campaign a success together!"
          />
        </>
      )}

      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDelete(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this advertiser?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </>
  );
};
