import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { NewTextField, Grid, Button, Typography } from '../../common';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { useMutation, useQuery } from 'react-query';
import Services from '../../service-utils/services';
import history from '../../history';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import { getSessionData } from '../../service-utils';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ApplyVoucher = ({ refetchGetBalance, handleBackButton }) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [serverErrors, setServerErrors] = useState({});
  const { agencyId } = getSessionData();
  const queryParams = queryString.parse(location.search);
  const { voucherCode: urlVoucherCode } = queryParams;

  const { data: voucherData = {}, refetch } = useQuery(
    ['VOUCHER_DATA', agencyId, urlVoucherCode],
    async () => {
      const response = await Services.getVoucherDetails(agencyId, urlVoucherCode);
      return response?.data?.voucher;
    },
    { enabled: !!agencyId && !!urlVoucherCode },
  );

  const { mutate: applyVoucher, isLoading: isLoadingapplyVoucher } = useMutation(
    async ({ inputVoucherCode }) => {
      const response = await Services.agencyApplyVoucher(agencyId, inputVoucherCode);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        history.push(`/agency/billing/account#1`);
        enqueueSnackbar('Voucher Applied successfully.', { variant: 'success' });
        handleBackButton();
        refetchGetBalance();
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      inputVoucherCode: urlVoucherCode || '',
    },
    onSubmit: (values) => {
      applyVoucher(values);
    },
  });

  useEffect(() => {
    setServerErrors({});
  }, [values]);

  useEffect(() => {
    if (voucherData?.voucherCode && voucherData.voucherCode !== values.inputVoucherCode) {
      setFieldValue('inputVoucherCode', voucherData.voucherCode);
    }
  }, [voucherData, setFieldValue, values.inputVoucherCode]);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="p-2">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="d-flex justify-content-center align-items-center my-3">
                <Typography>Apply Voucher</Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <NewTextField
                variant="outlined"
                label="Enter Voucher Code"
                margin="normal"
                fullWidth
                name="inputVoucherCode"
                value={values.inputVoucherCode}
                onChange={(event) => setFieldValue('inputVoucherCode', event.target.value)}
                onBlur={handleBlur}
                error={touched.inputVoucherCode && !!errors.inputVoucherCode}
                helperText={touched.inputVoucherCode && errors.inputVoucherCode}
              />
            </Grid>
          </Grid>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <Button
              type="submit"
              className="btn btn-primary mt-2 w-40 button-container"
              size="medium"
            >
              {isLoadingapplyVoucher ? 'Applying...' : 'Apply'}
            </Button>
          </div>
        </form>
        {isLoadingapplyVoucher && <OverlapLoader />}
      </div>
    </div>
  );
};

export default ApplyVoucher;
