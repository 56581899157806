import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SortIcon from '@material-ui/icons/Sort';
import BookIcon from '@material-ui/icons/MenuBook'; // Added icon
import SideNavigationMenu from './menuItem';
import { UserMenu } from './UserMenu';
import { useHistory, useLocation } from 'react-router-dom';
import './side-nav.scss';
import Logo from '../../../assets/img/adzy.png';
import LogoSquare from '../../../assets/img/adzy-square.png';
import history from '../../../history';
import { Link } from '@material-ui/core';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: 'var(--sidebar-bg)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: 'var(--sidebar-bg)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 'inherit',
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9),
    // },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const SideNavigation = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { open, handleDrawerClose } = props;
  const [isSideNavOpen, setSideNavigation] = useState(open);
  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    if (!isSideNavOpen) {
      setSideNavigation(false);
      props.handleDrawerOpen('hover');
    }
    setHover(true);
  };
  const goToLandingPage = () => {
    //console.log('landing page');
    history.push('/dashboard');
  };
  const openSideNavigation = () => {
    setSideNavigation(true);
    props.handleDrawerOpen('onClick');
    localStorage.setItem('sideNav', true);
  };
  const closeSideNav = () => {
    setSideNavigation(false);
    handleDrawerClose();
    localStorage.setItem('sideNav', false);
  };

  const onHoverClose = () => {
    if (!isSideNavOpen) {
      setSideNavigation(false);
      handleDrawerClose();
    }
    setHover(false);
  };
  // Check if the current path is under /advertiser
  const isAdvertiserPage = location.pathname.startsWith('/advertiser');
  return (
    <div className={`tenant-admin-nav ${classes.root}`}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onMouseEnter={toggleHover}
        onMouseLeave={onHoverClose}
        disableRipple
      >
        <div className={classes.toolbar} style={{ height: '62px' }}>
          {!isSideNavOpen ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              {!isSideNavOpen && hover ? (
                <>
                  <p onClick={() => goToLandingPage()}>
                    <img className="logo mr-5" src={Logo} alt="" />
                  </p>
                  <SortIcon onClick={openSideNavigation} />
                </>
              ) : (
                <>
                  <img className="logo-sqaure mr-n3" src={LogoSquare} alt="" />
                </>
              )}
            </IconButton>
          ) : (
            <IconButton>
              {theme.direction === 'rtl' ? (
                <SortIcon />
              ) : (
                <>
                  <a onClick={() => goToLandingPage()}>
                    <img className="logo mr-5" src={Logo} alt="" />
                  </a>
                  <ArrowBackIcon onClick={closeSideNav} />
                </>
              )}
            </IconButton>
          )}
        </div>
        <div className="d-flex menu-sidebar">
          <SideNavigationMenu {...props} open={open} />
          {isAdvertiserPage ? (
            <MenuItem onClick={() => history.push('/dashboard')}>
              <ListItemIcon>
                <ArrowBackIcon fontSize="small" />
              </ListItemIcon>
              <Typography style={{ color: 'white' }} className="ml-3">
                Home
              </Typography>
            </MenuItem>
          ) : null}
        </div>

        <div className="mb-2">
          <MenuItem>
            <ListItemIcon>
              <BookIcon fontSize="small" style={{ height: '22px' }} />
            </ListItemIcon>
            <Link href="https://docs.adzylytics.com/" passHref target="_blank">
              <Typography style={{ color: 'white' }} className="ml-3">
                Documentation
              </Typography>
            </Link>
          </MenuItem>
        </div>
      </Drawer>
    </div>
  );
};
export default SideNavigation;
