import React, { useState } from 'react';
import { Summary } from './summary';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ImpressionTrendChart } from './ImpressionTrendChart';
import { CampaignImpressionChart } from './CampaignImpressionChart';
import { StateImpressionChart } from './StateImpressionChart';
import { DeviceTypeImpressionChart } from './DeviceTypeImpressionChart';
import CampaignsByCategoryChart from './CampaignsByCategoryChart';
import { NewDateRangePicker, NewSelect } from './../../common';
import { ImpressionByCategory } from './ImpressionByCategory';
import { Button } from '../../common';
import { exportToPdf } from '../../common/utils';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';

const chartFactor = [
  { label: 'Impressions', value: 'Impressions' },
  { label: 'Click', value: 'Click' },
  { label: 'Spend', value: 'Spend' },
];
// const chartFactorForTrendline = [
//   { label: 'DAILY', value: 'DAILY' },
//   { label: 'HOURLY', value: 'HOURLY' },
// ];

const Overview = () => {
  const downloadPDFUtil = () => {
    const data = [
      // {
      //   element: '.summary-chart',
      //   heightToWidthRatio: 0.7,
      // },
      {
        element: '.impressions-chart',
        heightToWidthRatio: 0.7,
      },
      {
        element: '.geographical-chart',
        heightToWidthRatio: 0.7,
      },
      {
        element: '.campaigns-chart',
        heightToWidthRatio: 0.7,
      },
      {
        element: '.category-chart',
        heightToWidthRatio: 0.7,
      },
      {
        element: '.category-ctr-chart',
        heightToWidthRatio: 0.7,
      },
      {
        element: '.platform-chart',
        heightToWidthRatio: 0.7,
      },
    ];
    exportToPdf(data, 'overview');
  };

  // Default to last 1 month
  const [selectedDate, setSelectedDate] = useState([
    moment().subtract(1, 'month').toDate(),
    moment().toDate(),
  ]);
  const [chartType, setChartType] = useState(chartFactor[0]);
  // const [chartTypeForTrendLine, setChartTypeForTrendLine] = useState(chartFactorForTrendline[0]);
  return (
    <>
      {/* <div className="d-flex justify-content-end mb-2 ">
        <Button
          className="btn btn-primary btn-38 ml-3"
          size="large"
          onClick={downloadPDFUtil}
          endIcon={<CloudDownloadIcon />}
          style={{ fontWeight: 'bold' }}
        >
          PDF
        </Button>
      </div> */}
      <div className="dashboard-page">
        <Summary />

        <Grid container spacing={3} className="mt-2">
          <Grid item xs={12} md={12} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container impressions-chart">
              <div className="p-3">
                <div className="filters d-flex justify-content-between">
                  <Typography component="h5" variant="h6" className="pb-2">
                    Impressions in last 30 days
                  </Typography>
                  <div className="mt-n3">
                    <NewDateRangePicker
                      info="Select date range from where you want to see the report"
                      initialRange={selectedDate}
                      timePicker={true}
                      selected={selectedDate}
                      maxDate={selectedDate[1]}
                      onCallback={(start, end) => setSelectedDate([start, end])}
                    />
                  </div>
                </div>
                <ImpressionTrendChart selectedDate={selectedDate} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container geographical-chart">
              <div className="p-3">
                <Typography component="h5" variant="h6" className="pb-2">
                  Geographical Distribution of Impressions
                </Typography>
                <StateImpressionChart />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container campaigns-chart">
              <div className="p-3">
                <Typography component="h5" variant="h6" className="pb-2">
                  Impressions by Campaigns
                </Typography>
                <CampaignImpressionChart />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container platform-chart">
              <div className="p-3">
                <Typography component="h5" variant="h6" className="pb-2">
                  Platform viz Distribution of Impressions
                </Typography>
                <DeviceTypeImpressionChart />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container category-chart">
              <div className="p-3">
                <div className="filters d-flex justify-content-between">
                  <Typography component="h5" variant="h6" className="pb-2">
                    Campaign's Category
                  </Typography>
                  <NewSelect
                    required
                    options={chartFactor}
                    value={chartType}
                    onChange={(obj) => setChartType(obj)}
                    placeholder={`Type`}
                    className="mt-n3 "
                  />
                </div>
                <CampaignsByCategoryChart chartType={chartType.value} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container  category-ctr-chart">
              <div className="p-3">
                <div className="filters d-flex justify-content-between">
                  <Typography component="h5" variant="h6" className="pb-2">
                    CTR by Category
                  </Typography>
                </div>
                <ImpressionByCategory />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Overview;
