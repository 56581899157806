import React, { useEffect, useState } from 'react';
import { NewTextField, Grid, Button, Typography } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { Switch, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import { getSessionData } from '../../service-utils';
import { getCurrencySymbol } from '../../common/utils';

export const Optimization = ({ payload = {}, goToNextStep, lineItemId, lineItemData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { campaignId = '', agencyId = '', advertiserId = '' } = payload;
  const { agencyCurrency } = getSessionData();
  const [useSpread, setUseSpread] = useState(false);

  const { data: OptimizationData = {} } = useQuery(
    ['USER_DATA', advertiserId, campaignId, lineItemId, agencyId],
    async () => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.getLineItemOptimization(
        advertiserId,
        campaignId,
        lineItemId,
        query,
      );
      return response?.data?.lineItemOptimizationDTO;
    },
    { enabled: !!agencyId },
  );

  const { mutate: updateOptimization, isLoading } = useMutation(
    async (values) => {
      const { cpm, ctr } = values;
      const payload = {
        lineItemId,
        cpm: cpm || '0',
        ctr: ctr ? ctr.replace('%', '') : '0',
        targetedCpm: null,
        targetedCtr: null,
        useSpread: useSpread,
      };
      const query = queryString.stringify({ agencyId });
      return await Services.lineItemOptimization(
        advertiserId,
        campaignId,
        lineItemId,
        query,
        payload,
      );
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error in updating Optimization.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar('Updated successfully.', { variant: 'success' });
        goToNextStep(lineItemId);
      },
    },
  );
  const currencyData = getCurrencySymbol(agencyCurrency);

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      cpm: '',
      ctr: '',
    },
    validationSchema: Yup.object({
      cpm:
        currencyData === '$'
          ? Yup.number().min(0.5, `Cpm must be greater than or equal to ${currencyData}0.5`)
          : Yup.number().min(40, `Cpm must be greater than or equal to ${currencyData}40`),

      ctr: Yup.string().max(255).required('CTR is required.'),
    }),
    onSubmit: () => updateOptimization(values),
  });

  useEffect(() => {
    if (!isEmpty(OptimizationData)) {
      setFieldValue('cpm', OptimizationData.cpm || '0');
      setFieldValue('ctr', `${OptimizationData.ctr || '0'}%`);
      setUseSpread(OptimizationData.useSpread || false);
    }
  }, [OptimizationData, setFieldValue]);

  const handleUseSpreadToggle = () => {
    setUseSpread((prev) => !prev);
  };

  const handleCTRChange = (event) => {
    const value = event.target.value.replace('%', '');
    setFieldValue('ctr', `${value}%`);
  };

  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <div className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            Optimization
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center mb-3">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Placement Spread</span>
              <Tooltip
                title={
                  <Typography className="TooltipFontSize">
                    Placement Spread: if selected, spend will be distributed across selected
                    websites and applications.
                  </Typography>
                }
                placement="bottom"
              >
                <IconButton aria-label="info">
                  <InfoOutlinedIcon className="infoIcon" />
                </IconButton>
              </Tooltip>
            </div>
            <Switch checked={useSpread} onChange={handleUseSpreadToggle} color="primary" />
          </div>

          <Grid container direction="column" spacing={2} alignItems="center">
            {lineItemData?.biddingStrategy === 1 && (
              <div className="d-flex justify-content-center">
                <Typography variant="body2" component="p" className="lh-1" style={{ color: 'red' }}>
                  Note:- CPM selected on the for the Fixed Price bidding strategy, will be final and
                  honoured for bidding on ad server. You would see the updated value here, if not
                  updated yet.
                </Typography>
              </div>
            )}
            <Grid item style={{ width: '100%', maxWidth: '250px' }}>
              <NewTextField
                required
                info="System will never spend more than provided cost for every 1000 impression"
                error={Boolean(touched.cpm && errors.cpm)}
                fullWidth
                helperText={touched.cpm && errors.cpm}
                label="CPM"
                margin="normal"
                name="cpm"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('cpm', event.target.value)}
                value={values.cpm}
                variant="outlined"
                type="number"
                inputProps={{
                  // min: agencyCurrency === 'INR' ? 40 : 0.5,
                  readOnly: lineItemData?.biddingStrategy === 1,
                }}
              />
            </Grid>
            <Grid item style={{ width: '100%', maxWidth: '250px' }}>
              <NewTextField
                required
                info="System will try to bid on placement where desired CTR can be achieved"
                error={Boolean(touched.ctr && errors.ctr)}
                fullWidth
                helperText={touched.ctr && errors.ctr}
                label="CTR"
                margin="normal"
                name="ctr"
                onBlur={handleBlur}
                onChange={handleCTRChange}
                value={values.ctr}
                variant="outlined"
                type="text"
              />
            </Grid>
          </Grid>

          <div className="mt-4 d-flex justify-content-center">
            <Button
              variant="contained"
              className="btn btn-secondary d-flex"
              onClick={history.goBack}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
              {isLoading ? 'Submitting' : 'Submit'}
            </Button>
          </div>
          {isLoading && <OverlapLoader />}
        </form>
      </div>
    </div>
  );
};
