import React, { useEffect, useState, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Grid, Button, Paper, Typography } from '../../common';
import { useMutation, useQuery } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import Services from '../../service-utils/services';
import { getSessionData } from '../../service-utils';
import { handleMsgOnForm } from '../../common/utils';
import history from '../../history';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { EVENT_URL } from '../../common/constants';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Tooltip } from '@material-ui/core';

const ConversionCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [serverErrors, setServerErrors] = useState({});
  const { conversionId } = useParams();
  let { agencyId, agencyCurrency } = getSessionData();
  const [scriptToCopy, setScriptToCopy] = useState('');
  const [newConversionId, setNewConversionId] = useState(null);
  const textareaStyle = {
    width: '850px',
    height: '370px',
    marginRight: '10px', // Adjust spacing between textarea and icon as needed
  };
  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/conversion', // Replace with your desired path
        },
      }),
    );
  }, [dispatch]);

  const { mutate: CreateConversion, isLoading: isLoadingCreateConversion } = useMutation(
    async (values) => {
      const { name, action } = values;
      const payload = {
        name: name,
        action: action,
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.conversionCreate(query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        console.log(data?.conversionDTO?.id);
        setNewConversionId(data?.conversionDTO?.id);
        enqueueSnackbar('Conversion Code Generated Successfully.', { variant: 'success' });
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      action: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Conversion Name is required.'),
      action: Yup.string().max(255).required('Action is required.'),
    }),
    onSubmit: () => CreateConversion(values),
  });

  const {
    data: conversionData,
    isLoading: conversionLoading,
    refetch: reload,
  } = useQuery(
    ['GETCONVERSION_DATA', conversionId || newConversionId, agencyId],
    async () => {
      const queryStringParams = {
        agencyId,
      };
      const response = await Services.getConversion(
        conversionId || newConversionId,
        queryString.stringify(queryStringParams),
      );
      const script = conversionTrackingScript(
        response?.data?.conversionDTO?.code,
        response?.data?.conversionDTO?.action,
      );
      setScriptToCopy(script);
      return response?.data?.conversionDTO;
    },
    { enabled: (!!agencyId && !!conversionId) || !!newConversionId },
  );

  // Updated function to accept both `conversionId` and `action`
  const conversionTrackingScript = (conversionId, action) => {
    const DIV_ID = 'adzy' +Math.random().toString(36).slice(2);
    const script = `
    /*<script src="https://content.adzylytics.com/static/scripts/minadzyidgen.js?q=1"></script>
      <script>
        (function() {
          var conversionValue = "0.00";  // Replace with conversion value. Amount of sale.
          var txnId = "";  // Optinally you can put your payment transaction id.
          function trackConversion() {
            txnId = txnId == "" ? makeId(11) : txnId; 
            var img = document.createElement("img");
            img.src = "${EVENT_URL}/events/conversion/${agencyId}/${conversionId}?action=${action}" + "&price=" + conversionValue + "&currency=${agencyCurrency}&txnId="+txnId;
            img.style.display = "none";
            img.style.height = "1px";
            img.style.width = "1px";
            document.body.appendChild(img);
          }
          trackConversion();
        })();
      </script>*/
    <div id="${DIV_ID}">
        <script src="https://content.adzylytics.com/static/scripts/conversion.min.js?p=1"></script>
        <script>
            var conversionValue = '0.00';  // Replace 0.00 with amount of sale if application.
            var txnId = '';  // Optinally if you have your payment transaction id else keep it as is.

            //except above 2 parameters do not modify any other values.
            var divId = "${DIV_ID}";
            trackConversion(divId, ${EVENT_URL}, ${agencyId}, ${conversionId}, ${action}, conversionValue, ${agencyCurrency}, txnId);
        </script>
    </div>
    `;
    return script;
  };

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    enqueueSnackbar('Script copied to clipboard!', { variant: 'success' });
  };
  console.log(conversionData?.action);

  useEffect(() => {
    setFieldValue('name', conversionData?.name);
    setFieldValue('action', conversionData?.action);
  }, [conversionData, setFieldValue]);
  return (
    <>
      <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              {conversionId ? 'Generated Conversion Code' : 'Generate Conversion Code'}
            </Typography>
          </div>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Give a suitable Conversion name."
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Conversion Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                  value={values.name}
                  variant="outlined"
                  disabled={!!conversionId || !!newConversionId}
                />
                {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter your action.for eg Purchase / Register / Lead.."
                  error={Boolean(touched.action && errors.action)}
                  fullWidth
                  helperText={touched.action && errors.action}
                  label="Action"
                  margin="normal"
                  name="action"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('action', event.target.value)}
                  value={values.action}
                  variant="outlined"
                  disabled={!!conversionId || !!newConversionId}
                />
                {serverErrors.action && <p className="text-danger mt-n2">{serverErrors.action}</p>}
              </Grid>
            </Grid>
            <div className="mt-4 d-flex justify-content-end ">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!!conversionId || !!newConversionId}
                className="btn btn-primary d-flex ml-4"
              >
                generate code
              </Button>
              {isLoadingCreateConversion || (conversionLoading && <OverlapLoader />)}
            </div>
          </form>
        </Paper>
      </div>
      {(!!conversionId || !!newConversionId) && (
        <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
          <Paper className="pt-3 p-4 mn-h-200">
            <div className="d-flex justify-content-center mb-3">
              <Typography color="textPrimary" variant="h6">
                Conversion Code Script
              </Typography>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Typography color="textPrimary">
                Please use the following conversion code on your website's thank you page.
              </Typography>
            </div>
            <div className="d-flex align-items-center justify-content-center  mt-2">
              <textarea id="conversionScript" value={scriptToCopy} readOnly style={textareaStyle} />
              <Tooltip title="Copy Script">
                <FileCopyIcon
                  className="ml-2 cursor-pointer text-primary"
                  onClick={() => {
                    copyToClipboard(scriptToCopy);
                  }}
                />
              </Tooltip>
            </div>
          </Paper>
        </div>
      )}
    </>
  );
};

export default ConversionCreate;
