import React, { useState, useEffect, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Card, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Services from '../../../service-utils/services';
import getSessionData from './../../../service-utils/session-util';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { setHeader } from '../../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { isEmpty, orderBy } from 'lodash';
import { Button, NewSelect, TextField, NewDateRangePicker } from './../../../common';
import { CampaignTrendline } from './CampaignTrendline';
import { LineItemWiseImpressions } from './LineItemWiseImpressions';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../../common/utils';
import { ImpressionDeviceOS } from './ImpressionDeviceOS';
import { ImpressionDeviceType } from './ImpressionDeviceType';
import { ImpressionStateWise } from './ImpressionStateWise';
import { ImpressionPlacementWise } from './ImpressionPlacementWise';
import CardContent from '@material-ui/core/CardContent';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import spendIcon from '../../../assets/icon/spend.png';
import { getCurrencySymbol } from './../../../common/utils';
import { numberFormatter } from './../../../common/utils';
import { exportToPdf } from '../../../common/utils';
import { getEpochDate } from '../../../components/format-date-moment';
import moment from 'moment';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useAdvertiser } from '../../../context/AdvertiserProvider';
import { PLACEMENT_WISE_IMPRESSIONS_LIMIT } from '../../../libs/constants';
import { CreativeWiseImpression } from './CreativeWiseImpression';
import logo from '../../../assets/img/adzy.png';
import { CONTENT_URL } from '../../../common/constants';

import SelectWithItems from '../select-item';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const CampaignDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [campaignOptions, setCampaignOptions] = useState([]);
  const dispatch = useDispatch();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const [selectedDate, setSelectedDate] = useState([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);
  const [selectedLineitem, setSelectedLineitem] = useState([]);
  const [selectedCreative, setSelectedCreative] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [selectedDeviceOS, setSelectedDeviceOs] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [payload, setPaylod] = useState();
  const selectedCampaignId = selectedCampaign?.id;
  const [selectPlacementLimit, setSelectPlacementLimit] = useState();
  const [placementLimit, setPlacementLimit] = useState(PLACEMENT_WISE_IMPRESSIONS_LIMIT[0]);
  const [isLoading, setIsLoading] = useState();
  // Effect to update selectPlacementLimit state when placementLimit changes
  useEffect(() => {
    setSelectPlacementLimit(placementLimit);
  }, [placementLimit]);
  const {
    data: allData = {},
    isLoading: isLoadingAllData,
    error: dataError,
  } = useQuery(
    ['ALL_DATA', agencyId, advertiserId],
    async () => {
      const advertiserQueryStringParams = {
        pageSize: 5000,
        pageNum: 0,
        agencyId,
      };
      const advertiserResponse = await Services.getmasterADCampaign(
        advertiserId,
        queryString.stringify(advertiserQueryStringParams),
      );
      const advertiser = advertiserResponse.data?.advertiser || [];
      return {
        advertiser,
        campaignMasterList,
      };
    },
    {
      enabled: !!agencyId,
    },
  );
  useEffect(() => {
    if (selectedCampaign) {
      setSelectedDeviceOs([]);
      setSelectedDevice([]);
      setSelectedCreative([]);
      setSelectedCountry([]);
      setSelectedLineitem([]);
      setSelectedLineitem([]);
    }
  }, [selectedCampaign]);

  const { data: filtersData } = useQuery(
    ['CAMPAIGN_DASHBOARD_FILTERS', agencyId, selectedCampaignId],
    async () => {
      const queryStringParams = {
        agencyId,
      };
      const response = await Services.getCampaignCreativeList(
        selectedCampaignId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    { enabled: !!agencyId && !isEmpty(selectedCampaign) },
  );

  useEffect(() => {
    if (selectedCampaign) {
      setSelectedDeviceOs([]);
      setSelectedDevice([]);
      setSelectedCreative([]);
      setSelectedCountry([]);
      setSelectedLineitem([]);
      setSelectedLineitem([]);
    }
  }, [selectedCampaign]);

  const { data: campaignSummaryData } = useQuery(
    ['CAMPAIGN_DASHBOARD_SUMMARY', agencyId, advertiserId, selectedCampaignId],
    async () => {
      const queryStringParams = {
        agencyId,
      };
      const response = await Services.getmasterADCampaignSummary(
        selectedCampaignId,
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response.data.campaignDTO;
    },
    { enabled: !!agencyId && !isEmpty(selectedCampaign) },
  );

  const { data: agencyData = {}, refetch } = useQuery(
    ['AGENCY_DATA', agencyId],
    async () => {
      const response = await Services.getAgency(agencyId);
      return response?.data?.agencyDto;
    },
    { enabled: !!agencyId },
  );

  useEffect(() => {
    if (selectedCampaign && selectedCampaign.startDateTime && selectedCampaign.endDateTime) {
      // Parse the API date strings into moment objects
      const startDate = moment(selectedCampaign.startDateTime, 'DD/MM/YYYY HH:mm');
      const endDate = moment(selectedCampaign.endDateTime, 'DD/MM/YYYY HH:mm');

      // Check if the moment objects are valid
      if (startDate.isValid() && endDate.isValid()) {
        // Set the selectedDate state with moment objects
        setSelectedDate([startDate, endDate]);
      } else {
        console.error('Invalid startDateTime or endDateTime in the selectedCampaign object.');
      }
    }
  }, [selectedCampaign]);
  const filters = useMemo(() => {
    let lineItemOptions = [];
    let creativeOptions = [];
    let countryOptions = [];
    let stateOptions = [];
    let deviceOptions = [];
    let deviceOsOptions = [];

    if (!isEmpty(filtersData?.detectedDeviceTypes)) {
      deviceOptions = filtersData?.detectedDeviceTypes?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    }
    if (!isEmpty(filtersData?.detectedDeviceOSList)) {
      deviceOsOptions = filtersData?.detectedDeviceOSList?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    }

    if (!isEmpty(filtersData?.masterCountryList)) {
      countryOptions = filtersData?.masterCountryList?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    }

    if (!isEmpty(filtersData?.masterStateList)) {
      stateOptions = filtersData?.masterStateList?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    }
    if (!isEmpty(filtersData?.lineItemIds)) {
      lineItemOptions = filtersData?.lineItemIds?.map((item) => {
        return {
          label: item.label || item.lineItemId,
          value: item.id,
        };
      });
    }
    if (!isEmpty(filtersData?.creativeIds)) {
      creativeOptions = filtersData?.creativeIds?.map((item) => {
        return {
          label: item.label || item.creativeIds,
          value: item.id,
        };
      });
    }

    setSelectedLineitem([]);
    setSelectedCreative([]);
    setSelectedDeviceOs([]);
    setSelectedDevice([]);
    setSelectedCountry([]);
    setSelectedState([]);

    return {
      lineItemOptions,
      creativeOptions,
      countryOptions,
      deviceOsOptions,
      deviceOptions,
      stateOptions,
    };
  }, [filtersData]);

  const fetchData = async () => {
    let payloadData = {};

    if (selectedCampaign) {
      payloadData = {
        countries: selectedCountry?.map((item) => item.value).join(','),
        states: selectedState?.map((item) => item.value).join(','),
        lineItemIds: selectedLineitem?.map((item) => item.value).join(','),
        creativeIds: selectedCreative?.map((item) => item.value).join(','),
        detectedDeviceTypes: selectedDevice?.map((item) => item.value).join(','),
        detectedDeviceOs: selectedDeviceOS?.map((item) => item.value).join(','),
        startDate: getEpochDate(selectedDate[0]),
        endDate: getEpochDate(selectedDate[1]),
      };
    }
    setPaylod(payloadData);

    // Make your API calls with the payloadData
    // Example: const response = await Services.someApiCall(payloadData);
  };
  useEffect(() => {
    fetchData(); // Call the API on initial load
  }, [selectedCampaign, selectedDate]);

  const handleApply = () => {
    fetchData(); // Call the API when the "Apply" button is clicked
    setPlacementLimit(PLACEMENT_WISE_IMPRESSIONS_LIMIT[0]);
  };

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Report
          </div>
        ),
      }),
    );
  }, [dispatch]);
  const { advertiser, campaignMasterList } = allData;

  let eCPC = campaignSummaryData?.totalClick
    ? campaignSummaryData?.totalSpent?.amount / campaignSummaryData.totalClick
    : 0;
  let eCPM = campaignSummaryData?.impressionsDelivered
    ? (campaignSummaryData?.totalSpent?.amount * 1000) / campaignSummaryData?.impressionsDelivered
    : 0;

  useEffect(() => {
    const campaigns = allData.advertiser?.campaignMasterList || [];

    if (!isEmpty(campaigns)) {
      const activeCampaigns = orderBy(
        campaigns.map((item) => ({
          ...item,
          label: item.campaignName,
          value: item.campaignId,
          disabled: item.status,
          id: item.campaignId,
        })),
        [(campaign) => campaign.label.toLowerCase()],
        ['asc'],
      );
      setCampaignOptions(activeCampaigns);
      setSelectedCampaign(activeCampaigns[0]);
    } else {
      // If no campaigns are available, reset the campaign options
      setCampaignOptions([]);
      setSelectedCampaign(null);
    }
  }, [allData.advertiser]);

  // const handleDownload = async () => {
  //   try {
  //     const query = queryString.stringify({
  //       agencyId,
  //       campaignId: selectedCampaign?.value,
  //     });

  //     const response = await Services.downloadCampaignReport(selectedCampaign?.value, query);

  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', `${selectedCampaign?.label}.csv`);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();

  //     enqueueSnackbar('Downloaded successfully.', { variant: 'success' });
  //   } catch (error) {
  //     handleMsgOnForm(error, enqueueSnackbar, setServerErrors);
  //   }
  // };

  const downloadPDFUtil = async () => {
    setIsLoading(true); // Start loading state
    try {
      const data = [
        { element: '.trendline-chart', heightToWidthRatio: 0.7 },
        { element: '.line_item-chart', heightToWidthRatio: 0.7 },
        { element: '.creative_wise-chart', heightToWidthRatio: 0.7 },
        { element: '.state_wise-chart', heightToWidthRatio: 0.7 },
        { element: '.device_os-chart', heightToWidthRatio: 0.7 },
        { element: '.device_type-chart', heightToWidthRatio: 0.7 },
        { element: '.placement_wise-chart', heightToWidthRatio: 0.7 },
      ];

      // Construct filter data, excluding fields with no selected items
      const filterData = {};

      if (advertiser) {
        filterData['Advertiser Name'] = advertiser?.advertiserName;
      }

      if (selectedCampaign?.label) {
        filterData['Campaign Name'] = selectedCampaign.label;
      }

      if (selectedLineitem.length > 0) {
        filterData['Line Items'] = selectedLineitem.map((item) => item.label).join(', ');
      }

      if (selectedCreative.length > 0) {
        filterData['Creatives'] = selectedCreative.map((item) => item.label).join(', ');
      }

      if (selectedDevice.length > 0) {
        filterData['Device Types'] = selectedDevice.map((item) => item.label).join(', ');
      }

      if (selectedDeviceOS.length > 0) {
        filterData['Device OS'] = selectedDeviceOS.map((item) => item.label).join(', ');
      }

      if (selectedCountry.length > 0) {
        filterData['Countries'] = selectedCountry.map((item) => item.label).join(', ');
      }

      if (selectedState.length > 0) {
        filterData['States'] = selectedState.map((item) => item.label).join(', ');
      }

      if (selectedDate.length === 2) {
        filterData['Date Range'] = `${moment(selectedDate[0]).format('DD/MM/YYYY')} - ${moment(
          selectedDate[1],
        ).format('DD/MM/YYYY')}`;
      }

      const agencyLogoUrl = agencyData.logoUrl ? `${CONTENT_URL}/${agencyData.logoUrl}` : '';

      Object.keys(filterData).forEach((key) => {
        if (!filterData[key] || filterData[key] === 'All') {
          delete filterData[key];
        }
      });

      const fileName = `${selectedCampaign?.label || 'Report'}_Report`; // Fallback to 'Report' if no campaign is selected

      await exportToPdf(data, fileName, logo, agencyLogoUrl, filterData);

      enqueueSnackbar('PDF report generated successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error generating PDF:', error);
      enqueueSnackbar('Error generating PDF report', { variant: 'error' });
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const SummaryBox = (props) => {
    const { title = '', icon, image, value, valuesData, titles, tooltipValue } = props;
    const displayValue = value ?? 0;
    return (
      <Card className="d-flex p-2">
        <div className="d-flex w-100 align-items-center">
          <div className="w-25 summary-icon">{icon ? icon : <img alt="image" src={image} />}</div>
          <div className="w-75">
            <Tooltip title={<Typography className="TooltipFontSize">{tooltipValue}</Typography>}>
              <CardContent className="p-2 mr-5">
                <div className="filters d-flex justify-content-between">
                  <Typography className="mt-1 font20 summarytitleColor">{title || ''}</Typography>
                  <Typography className="mr-2 h4 font30">{value || ''}</Typography>
                </div>

                <div className="dotted-line font20 summaryBoxBorder"></div>
                <div className="filters d-flex justify-content-between">
                  <Typography className="h4 mt-1 font30">{valuesData || ''}</Typography>
                  <Typography className="mt-2 font20 summarytitleColor">{titles || ''}</Typography>
                </div>
              </CardContent>
            </Tooltip>
          </div>
        </div>
      </Card>
    );
  };
  useEffect(() => {
    setPlacementLimit(PLACEMENT_WISE_IMPRESSIONS_LIMIT[0]);
  }, [selectedCampaign]);

  const handleSelectChange = (selectedOptions, currentItems, setItems) => {
    const updatedItems = [...currentItems, ...(selectedOptions || [])];
    const uniqueItems = Array.from(
      new Map(updatedItems.map((item) => [item.value, item])).values(),
    );
    setItems(uniqueItems);
  };

  // Reusable function for removing items
  const removeItem = (itemToRemove, currentItems, setItems) => {
    setItems(currentItems.filter((item) => item.value !== itemToRemove.value));
  };

  return (
    <div className="dashboard-page">
      <div className="d-flex justify-content-between align-items-start mb-3">
        <div className="d-flex filters">
          <NewSelect
            info="Select campaign name to view its report"
            required
            options={campaignOptions}
            value={selectedCampaign}
            onChange={(obj) => setSelectedCampaign(obj)}
            placeholder={`Campaign`}
            isMulti={false}
            className="mt-n3 ml-3"
            disabled={isLoadingAllData}
          />
          <div className="ml-3">
            <Button
              className="btn btn-primary btn-38"
              size="large"
              onClick={downloadPDFUtil}
              endIcon={<CloudDownloadIcon />}
              style={{ fontWeight: 'bold' }}
            >
              PDF
            </Button>
          </div>
        </div>
        <div className="mt-n3">
          <NewDateRangePicker
            info="Select date range from where you want to see the report. The date range should be between campaign period only."
            initialRange={selectedDate}
            timePicker={true}
            key={selectedDate}
            selected={selectedDate}
            minDate={selectedDate[0]}
            maxDate={selectedDate[1]}
            onCallback={(start, end) => setSelectedDate([start, end])}
          />
        </div>
      </div>
      <div className="summary-box">
        <Grid container spacing={3}>
          <Grid item xs={4} className="d-grid">
            <SummaryBox
              title="Impressions"
              icon={<RemoveRedEyeIcon className="font50 primary-color" />}
              value={`${numberFormatter(campaignSummaryData?.impressionsDelivered || 0)}`}
              valuesData={
                `${getCurrencySymbol(campaignSummaryData?.totalSpent?.currency)} ${numberFormatter(
                  eCPM,
                )}` || 0
              }
              titles="eCPM"
              tooltipValue={` Impressions: ${campaignSummaryData?.impressionsDelivered || 0}`}
            />
          </Grid>
          <Grid item xs={4} className="d-grid">
            <SummaryBox
              title="Clicks"
              icon={<TouchAppIcon className="font50 primary-color" />}
              value={` ${numberFormatter(campaignSummaryData?.totalClick || 0)}`}
              valuesData={`${
                campaignSummaryData?.ctr !== undefined ? `${campaignSummaryData.ctr}%` : '0%'
              }`}
              titles="CTR"
              tooltipValue={`Clicks: ${campaignSummaryData?.totalClick || 0}`}
            />
          </Grid>

          <Grid item xs={4} className="d-grid">
            <SummaryBox
              title="Total spent"
              image={spendIcon}
              value={
                ` ${getCurrencySymbol(campaignSummaryData?.totalSpent?.currency)} ${numberFormatter(
                  campaignSummaryData?.totalSpent?.amount,
                )}` || 0
              }
              valuesData={
                `${getCurrencySymbol(campaignSummaryData?.currency)} ${numberFormatter(eCPC)}` || 0
              }
              titles="eCPC"
              tooltipValue={
                `Total spent: ${getCurrencySymbol(campaignSummaryData?.totalSpent?.currency)} ${
                  campaignSummaryData?.totalSpent.amount
                }` || 0
              }
            />
          </Grid>
        </Grid>
      </div>
      <div className="mb-3 d-flex filters mt-4">
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <SelectWithItems
              info="Select line item or multiple line items you want to see the reports in detail"
              options={filters?.lineItemOptions}
              value={[]}
              isMulti={false}
              disabled={false}
              placeholder="Line Items"
              selectedItems={selectedLineitem}
              onSelectChange={(obj) =>
                handleSelectChange(obj, selectedLineitem, setSelectedLineitem)
              }
              onRemove={(item) => removeItem(item, selectedLineitem, setSelectedLineitem)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <SelectWithItems
              info="Select creative or multiple creatives for which you want to see the report"
              options={filters?.creativeOptions}
              placeholder="Creatives"
              selectedItems={selectedCreative}
              onSelectChange={(obj) =>
                handleSelectChange(obj, selectedCreative, setSelectedCreative)
              }
              onRemove={(item) => removeItem(item, selectedCreative, setSelectedCreative)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <SelectWithItems
              info="Select devices type or miultiple devices to see its detailed report"
              options={filters?.deviceOptions}
              onSelectChange={(obj) => handleSelectChange(obj, selectedDevice, setSelectedDevice)}
              placeholder={`Devices Type`}
              className="mt-n3 ml-3"
              selectedItems={selectedDevice}
              onRemove={(item) => removeItem(item, selectedDevice, setSelectedDevice)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <SelectWithItems
              info="Select device OS or multiple device OS  to see its detailed report"
              options={filters?.deviceOsOptions}
              onSelectChange={(obj) =>
                handleSelectChange(obj, selectedDeviceOS, setSelectedDeviceOs)
              }
              selectedItems={selectedDeviceOS}
              placeholder={`Devices Os`}
              className="mt-n3 ml-3"
              onRemove={(item) => removeItem(item, selectedDeviceOS, setSelectedDeviceOs)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <SelectWithItems
              info="Select country to see its detailed report"
              options={filters?.countryOptions}
              onSelectChange={(obj) => handleSelectChange(obj, selectedCountry, setSelectedCountry)}
              selectedItems={selectedCountry}
              placeholder={`Country`}
              className="mt-n3 ml-3"
              onRemove={(item) => removeItem(item, selectedCountry, setSelectedCountry)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <SelectWithItems
              info="Select states to see its detailed report"
              options={filters?.stateOptions}
              onSelectChange={(obj) => handleSelectChange(obj, selectedState, setSelectedState)}
              placeholder={`States`}
              selectedItems={selectedState}
              className="mt-n3 ml-3"
              onRemove={(item) => removeItem(item, selectedState, setSelectedState)}
            />
          </Grid>

          {/* <NewSelect
            required
            options={chartFactorForCampaign}
            value={chartTypeForCampaign}
            onChange={(obj) => {
              setChartTypeForCampaign(obj);
              fetchChartData(obj.label);
            }}
            placeholder={`Frequency`}
            className="mt-n3 ml-3"
          /> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          className="justify-content-center align-items-center"
        >
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3 "
            startIcon={<FilterListIcon />}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Grid>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} className="d-grid ">
          <Paper className="p-0 mn-h-200 chart-container trendline-chart">
            <div className="p-3">
              <Typography component="h5" variant="h6" className="pb-2">
                Trendline
              </Typography>
              <CampaignTrendline
                payload={payload}
                agencyId={agencyId}
                selectedCampaign={selectedCampaign}
                isFiltersLoading={isLoadingAllData}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="mb-5">
        <Grid item xs={12} md={6} className="d-grid ">
          <Paper className="p-0 mn-h-200 chart-container line_item-chart">
            <div className="p-3">
              <Typography component="h5" variant="h6" className="pb-2">
                Analytics by Line Item
              </Typography>
              <LineItemWiseImpressions
                payload={payload}
                agencyId={agencyId}
                selectedCampaign={selectedCampaign}
                isFiltersLoading={isLoadingAllData}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className="d-grid ">
          <Paper className="p-0 mn-h-200 chart-container creative_wise-chart">
            <div className="p-3">
              <Typography component="h5" variant="h6" className="pb-2">
                Analytics by Creatives
              </Typography>
              <CreativeWiseImpression
                payload={payload}
                agencyId={agencyId}
                selectedCampaign={selectedCampaign}
                isFiltersLoading={isLoadingAllData}
              />
            </div>
          </Paper>
        </Grid>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} md={8} className="d-grid">
            <Paper className="p-0 mn-h-200  chart-container state_wise-chart">
              <div className="p-3">
                <Typography component="h5" variant="h6" className="pb-2">
                  Analytics by State Wise
                </Typography>
                <ImpressionStateWise
                  payload={payload}
                  agencyId={agencyId}
                  selectedCampaign={selectedCampaign}
                  isFiltersLoading={isLoadingAllData}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} className="d-grid ">
          <Paper className="p-0 mn-h-200 chart-container device_os-chart">
            <div className="p-3">
              <Typography component="h5" variant="h6" className="pb-2">
                Analytics by Device OS
              </Typography>
              <ImpressionDeviceOS
                payload={payload}
                agencyId={agencyId}
                selectedCampaign={selectedCampaign}
                isFiltersLoading={isLoadingAllData}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className="d-grid ">
          <Paper className="p-0 mn-h-200 chart-container device_type-chart">
            <div className="p-3">
              <Typography component="h5" variant="h6" className="pb-2">
                Analytics by Device Type
              </Typography>
              <ImpressionDeviceType
                payload={payload}
                agencyId={agencyId}
                selectedCampaign={selectedCampaign}
                isFiltersLoading={isLoadingAllData}
              />
            </div>
          </Paper>
        </Grid>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} md={8} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container placement_wise-chart">
              <div className="p-3">
                <div className="filters d-flex justify-content-between">
                  <Typography component="h5" variant="h6" className="pb-2">
                    Analytics by Placement Wise
                  </Typography>
                  <div className="d-flex justify-content-center mr-5">
                    <NewSelect
                      // info="Select how many records you want to see for placements. e.g 50,100, 500"
                      options={PLACEMENT_WISE_IMPRESSIONS_LIMIT}
                      value={placementLimit}
                      onChange={(obj) => setPlacementLimit(obj)}
                      placeholder={`Records`}
                      className="mt-n3 mr-5 justify-content-center"
                      disabled={false}
                    />
                  </div>
                </div>
                <ImpressionPlacementWise
                  payload={payload}
                  agencyId={agencyId}
                  selectedCampaign={selectedCampaign}
                  isFiltersLoading={isLoadingAllData}
                  selectPlacementLimit={selectPlacementLimit}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CampaignDashboard;
