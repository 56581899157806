import React, { useEffect, useMemo, useState } from 'react';
import { Button, Loader } from '../../common';
import { TargetingOption } from './targeting-option';
import { isEmpty, isEqual } from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from 'react-query';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { handleMsgOnForm } from '../../common/utils';
import { useHistory } from 'react-router-dom';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { Grid } from '@material-ui/core';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
      style={{ padding: '16px', height: '100%' }}
    >
      {value === index && <Box className="h-100">{children}</Box>}
    </div>
  );
};

export const Targeting = ({ campaignId, lineItemId, expired, goToNextStep = () => {} }) => {
  const [value, setValue] = React.useState(0);
  const [payload, setPayload] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = React.useState(false);
  const [savedTargetingsData, setSavedTargetingsData] = useState({});
  let history = useHistory();
  const { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const queryStringParams = { agencyId };

  const { data: targetingMasterTypes = {}, isLoading: isTargetingMasterTypesLoading } = useQuery(
    ['TARGETING_MASTER'],
    async () => {
      const response = await Services.getTargetingMaster(queryString.stringify(queryStringParams));
      return response.data?.targetingDTOList;
    },
  );

  const {
    data: savedTargetings = {},
    isLoading: isSavedListLoading,
    refetch: refetchSavedTargettings,
  } = useQuery(
    ['SAVED_TARGETING', advertiserId, campaignId],
    async () => {
      const response = await Services.getSavedTargeting(
        advertiserId,
        campaignId,
        lineItemId,
        queryString.stringify(queryStringParams),
      );
      return response.data?.lineItemTargetingDTOMap;
    },
    { enabled: !!lineItemId },
  );

  useEffect(() => {
    // Only update if there are actual changes to merge
    const newTargetingData = {
      ...(!isEmpty(savedTargetings) ? savedTargetings : {}),
      ...(!isEmpty(payload) ? payload : {}),
    };

    // Only setState if the data is actually different
    if (!isEqual(savedTargetingsData, newTargetingData)) {
      setSavedTargetingsData(newTargetingData);
    }
  }, [payload, savedTargetings]); // Remove savedTargetingsData from dependencies

  const savedTargetingIds = useMemo(() => {
    return Object?.keys(savedTargetingsData)?.filter(
      (i) =>
        !isEmpty(savedTargetingsData[parseInt(i)]?.include) ||
        !isEmpty(savedTargetingsData[parseInt(i)]?.exclude),
    );
  }, [savedTargetingsData]);

  const { mutate: handleSubmit, isLoading: isLoading } = useMutation(
    async () => {
      const selectedTargettings = Object.values(savedTargetingsData);

      const payloadData = selectedTargettings.map((item) => {
        const { masterTargetingId, exclude, include } = item;
        return {
          exclude: exclude || [],
          include: include || [],
          lineItemId: parseInt(lineItemId),
          masterTargetingId: masterTargetingId,
        };
      });

      const response = await Services.updateTargeting(
        advertiserId,
        campaignId,
        lineItemId,
        queryString.stringify(queryStringParams),
        payloadData,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, () => {});
      },
      onSuccess: () => {
        refetchSavedTargettings();
        goToNextStep(lineItemId);
        enqueueSnackbar('Submitted successfully.', { variant: 'success' });
      },
    },
  );

  /*
  const handleReset = () =>{
    setSavedTargetingsData({})
  }

  const { mutate: handleReset } = useMutation(
    async () => {
      const { masterTargetingId } = payload;
      const payloadData = {
        lineItemId: parseInt(lineItemId),
        masterTargetingId: masterTargetingId,
      };
      const response = await Services.resetTargeting(
        advertiserId,campaignId, lineItemId,
        queryString.stringify( queryStringParams),

        payloadData,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err);
      },
      onSuccess: () => {
        refetchSavedTargettings();
        enqueueSnackbar('Resetted successfully.', { variant: 'success' });
      },
    },
  );
  */

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const targetingTypes = useMemo(() => {
    if (!isEmpty(targetingMasterTypes)) {
      return targetingMasterTypes.map((item) => {
        return {
          ...item,
          name: item?.label,
          type: item?.type,
        };
      });
    } else {
      return [];
    }
  }, [targetingMasterTypes]);

  return (
    <>
      {isTargetingMasterTypesLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={3} className="targeting-container">
            <Grid item xs={12} md={3}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                className="tabs"
                style={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}
              >
                {targetingTypes?.map((item, index) => {
                  return savedTargetingIds.includes(item.id.toString()) ? (
                    <Tab
                      className="targetting-added"
                      label={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <span>{item.name}</span>
                          <CheckIcon style={{ marginLeft: '8px', color: 'green' }} />
                        </div>
                      }
                      key={index}
                      data={item.endpoint}
                    />
                  ) : (
                    <Tab
                      className="targetting-tab"
                      label={
                        <span
                          style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}
                        >
                          {item.name}
                        </span>
                      }
                      key={index}
                      data={item.endpoint}
                    />
                  );
                })}
              </Tabs>
            </Grid>
            <Grid item xs={12} md={9}>
              {targetingTypes?.map((item, index) => (
                <TabPanel value={value} key={index} index={index}>
                  <TargetingOption
                    data={item}
                    savedTargetings={savedTargetingsData}
                    isSavedListLoading={isSavedListLoading}
                    setPayload={setPayload}
                  />
                </TabPanel>
              ))}
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
            <Button
              variant="contained"
              className="btn btn-secondary  my-2 mr-3 float-right"
              onClick={history.goBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="btn btn-secondary  my-2  float-right"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                goToNextStep(lineItemId);
              }}
              disabled={expired}
            >
              Next
            </Button>
            <Button
              disabled={expired}
              variant="contained"
              type="submit"
              className="btn btn-primary my-2 ml-3 float-right "
              onClick={() => handleSubmit()}
            >
              {isLoading ? 'Submiting' : 'Submit And Next'}
            </Button>
          </Box>

          <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <DialogTitle>Selected Targeting</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {!isEmpty(savedTargetingsData)
                  ? Object.keys(savedTargetingsData)?.map((key) =>
                      JSON.stringify(savedTargetingsData[key]),
                    )
                  : 'No Data'}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="p-3">
              <Button className="btn btn-secondary" size="medium" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button className="btn btn-secondary" size="medium" onClick={() => handleSubmit()}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {isLoading && <OverlapLoader />}
    </>
  );
};
